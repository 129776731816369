<template>
  <b-row>
    <b-col md="4" lg="4" v-for="stat in stats" :key="stat.name">
      <stat
        :variant="stat.variant"
        :icon="stat.icon"
        :name="stat.name"
        :value="stat.value"
        :initialValue="stat.initialValue"
        :unit="stat.unit"
        :gastoMensualVaporUSD="stat.gastoMensualVaporUSD"
        :gastoDiarioVaporUSD="stat.gastoDiarioVaporUSD"
        :percentDiff="stat.percentDiff"
        :ahorroDiarioVaporUSD="stat.ahorroDiarioVaporUSD"
        :ahorroMensualVaporUSD="stat.ahorroMensualVaporUSD"
      ></stat>
    </b-col>

    <b-col>
      <b-card class="text-center">
        <b-avatar
          class="mb-1"
          size="45"
          variant="light-secondary"
          badge-top
          badge-offset="-1.0em"
        >
          <feather-icon size="21" icon="DollarSignIcon" />
        </b-avatar>
        <div class="truncate">
          <h4 :class="ahorroMensualVaporUSDSumClass">
            {{ ahorroMensualVaporUSDSum }}
          </h4>
          <h4 :class="ahorroDiarioVaporUSDSumClass">
            {{ $t(ahorroDiarioVaporUSDSum) }}
          </h4>

          <span>{{$t("Ahorro Total (CE + VBP + VMP)")}}</span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template> 
    
    <script>
import
{
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BAvatar,

} from "bootstrap-vue";

const axios = require("axios");

import Stat from "./Stat.vue";

import VueSlider from 'vue-slider-component'


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    BAvatar,
    Stat

  },

  directives: {
  },

  watch: {
    // whenever question changes, this function will run
    stats: {
      deep: true,
      handler(newValue, oldValue)
      {
        console.log({ oldValue, newValue })

        let ahorroMensualVaporUSDSum = 0
        let ahorroDiarioVaporUSDSum = 0

        newValue.forEach(stat =>
        {

          ahorroMensualVaporUSDSum += stat.ahorroMensualVaporUSD
          ahorroDiarioVaporUSDSum += stat.ahorroDiarioVaporUSD

        })

        let dollarUSLocale = Intl.NumberFormat('en-US');

        if (ahorroMensualVaporUSDSum == 0)
        {
          this.ahorroMensualVaporUSDSum = ''
          this.ahorroMensualVaporUSDSumClass = ''
        }
        else if (ahorroMensualVaporUSDSum > 0)
        {
          this.ahorroMensualVaporUSDSum = "+" + dollarUSLocale.format(ahorroMensualVaporUSDSum) + " (USD/Mes)"
          this.ahorroMensualVaporUSDSumClass = "text-danger"
        }
        else
        {
          this.ahorroMensualVaporUSDSum = dollarUSLocale.format(ahorroMensualVaporUSDSum) + " (USD/Mes)"
          this.ahorroMensualVaporUSDSumClass = ''

        }


        if (ahorroDiarioVaporUSDSum == 0)
        {
          this.ahorroDiarioVaporUSDSum = ''
          this.ahorroDiarioVaporUSDSumClass = ""
        }
        else if (ahorroDiarioVaporUSDSum > 0)
        {
          this.ahorroDiarioVaporUSDSum = "+" + dollarUSLocale.format(ahorroDiarioVaporUSDSum) + " (USD/Dia)"
          this.ahorroDiarioVaporUSDSumClass = "text-danger"
        }
        else
        {
          this.ahorroDiarioVaporUSDSum = dollarUSLocale.format(ahorroDiarioVaporUSDSum) + " (USD/Dia)"
          this.ahorroDiarioVaporUSDSumClass = ""
        }



      }
    }
  },


  data: function ()
  {

    return {

      ahorroMensualVaporUSDSum: '',
      ahorroDiarioVaporUSDSum: '',

      ahorroMensualVaporUSDSumClass: '',
      ahorroDiarioVaporUSDSumClass: '',


    };
  },
  created()
  {

  },

  mounted()
  {

  },

  methods: {



  },


  props: {
    stats: {
      type: Array,
      required: true
    },
  },

};
    </script>
    
    <style lang="scss"></style>
    