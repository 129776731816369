<template>
  <b-card class="text-center">
    <b-avatar
      class="mb-1"
      :variant="variant"
      size="45"
      :badge="badge"
      :badge-variant="badgeVariant"
      badge-top
      badge-offset="-1.0em"
    >
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ this.roundValue(value, 4) }}
      </h2>
      <h4>
        {{ ahorroGastoMensual }} 
      </h4>
      <h4>
        {{ ahorroGastoDiario }}
      </h4>      
      <span>{{ $t(name) }} ({{ unit }})</span>

      
    </div>
  </b-card>
</template>
      
      <script>
import
{
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BAvatar,

} from "bootstrap-vue";

const axios = require("axios");

import VueSlider from 'vue-slider-component'


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    BAvatar

  },

  directives: {
  },

  data: function ()
  {
    return {
      badge: false,
      badgeVariant: 'primary',
      ahorroGastoDiario: '',
      ahorroGastoMensual: '',

    };
  },
  created()
  {

  },
 
  mounted()
  {

  },

  methods: {

    roundValue(value, decimales = 2)
    {
      let tmp = Math.pow(10, decimales)

       return Math.round(value * tmp) / tmp
    }



  },



  watch: {
    // whenever question changes, this function will run
    value: {
      handler(newValue, oldValue)
      {

        let dollarUSLocale = Intl.NumberFormat('en-US');

        if (this.percentDiff == 0)
        {
          this.badgeVariant = 'secondary'
          this.badge = this.roundValue(100*this.percentDiff) + "%"
          this.ahorroGastoMensual = ''
          this.ahorroGastoDiario = ''
        }
        else if (this.percentDiff > 0)
        {
          this.badgeVariant = 'danger'
          this.badge = "+" + this.roundValue(100*this.percentDiff) + "%"
          this.ahorroGastoMensual = "+" + dollarUSLocale.format(this.ahorroMensualVaporUSD) + " (USD/Mes)"
          this.ahorroGastoDiario = "+" + dollarUSLocale.format(this.ahorroDiarioVaporUSD) + " (USD/Dia)"
        }
        else
        {
          this.badgeVariant = 'success'
          this.badge = this.roundValue(100*this.percentDiff) + "%"
          this.ahorroGastoMensual = dollarUSLocale.format(this.ahorroMensualVaporUSD) + " (USD/Mes)"
          this.ahorroGastoDiario = dollarUSLocale.format(this.ahorroDiarioVaporUSD) + " (USD/Dia)"
        }


      },

    }
  },


  props: {

    variant: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    unit: {
      type: String,
      required: true
    },

    gastoMensualVaporUSD: {
      type: Number | String,
      required: true

    },

    gastoDiarioVaporUSD: {
      type: Number | String,
      required: true

    },

    ahorroDiarioVaporUSD: {
      type: Number | String,
      required: true

    },

    ahorroMensualVaporUSD: {
      type: Number | String,
      required: true

    },


    



    value: {
      type: Number | String,
      required: true
    },

    percentDiff: {
      type: Number | String,
      required: true
    },    

    initialValue: {
      type: Number | String,
      required: true
    }
    /*
    badge: {
      type: Boolean,
      default: () => false
    }*/

  },

};
      </script>
      
      <style lang="scss"></style>
      