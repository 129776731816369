<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $t(cardTitle) }}</b-card-title>
        <b-card-sub-title
          >{{ $t('Selecciona los valores de cada variable para simular escenarios') }}</b-card-sub-title
        >
      </div>

      <!-- Card Actions -->
      <!--
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li></li>
          <li>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search"
                debounce="400"
                @input="searchChange"
              />
            </b-input-group>
          </li>
        </ul>
      </div>
    --></div>

    <!-- Card Body  -->
    <b-card-body style="width: 100%">


      <b-row style="margin-top: 3rem; margin-bottom: 1rem;">
        <b-col>
          <h2>MP16</h2>
        </b-col>
      </b-row>      

      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics.filter(metric => metric.category == 'mp16')" :key="metric.name" >
          <b-form-checkbox
            v-model="metric.show"
            :value="true"
            :unchecked-value="false"
            switch
          >
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
          xl="6"
          v-for="metric in metrics.filter(metric => metric.category == 'mp16')"
          :key="metric.name"
          v-show="metric.show"
        >
          <b-row 
            style="
              margin: 1rem;
              padding: 1rem;
              border: 1px solid;
              border-color: silver;
            "
          >
            <b-col lg="9" xl="9">
              <label :for="'slider-input-' + metric.name">{{
                metric.name
              }}</label>

              <vue-slider
                style=""
                :id="'slider-input-' + metric.name"
                v-model="metric.value"
                direction="ltr"
                :tooltip="'active'"
                :min="metric.min"
                :marks="metric.marks"
                :max="metric.max"
                :data="metric.data || undefined"
                :interval="metric.interval"
                class="mb-2 vue-slider-secondary"
                @change="(value, index) => onChange(metric.name, value)"
                @drag-end="(index) => onDragEnd(metric.name)"
              />
            </b-col>
            <b-col>
              <label :for="'input-' + metric.name"> </label>
              <b-form-input
                :id="'input-' + metric.name"
                v-model="metric.value"
                placeholder=""
                @input="onChange"
                :readonly="metric.disabledInput"
              ></b-form-input>
            </b-col>
            <!--
            <b-col>
              <label :for="'checkbox-' + metric.name"> </label>
              <b-form-checkbox
              :id="'checkbox-' + metric.name"
      v-model="status"
      name="checkbox-1"
      value="accepted"
      unchecked-value="not_accepted"
    >
      
    </b-form-checkbox>  
            
            
            </b-col>
            -->
          </b-row>
        </b-col>
      </b-row>



      <b-row style="margin-top: 3rem; margin-bottom: 1rem;">
        <b-col>
          <h2>Fibra</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics.filter(metric => metric.category == 'fibra')" :key="metric.name">
          <b-form-checkbox
            v-model="metric.show"
            :value="true"
            :unchecked-value="false"
            switch
          >
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
          xl="6"
          v-for="metric in metrics.filter(metric => metric.category == 'fibra')"
          :key="metric.name"
          v-show="metric.show"
        >
          <b-row 
            style="
              margin: 1rem;
              padding: 1rem;
              border: 1px solid;
              border-color: silver;
            "
          >
            <b-col lg="9" xl="9">
              <label :for="'slider-input-' + metric.name">{{
                metric.name
              }}</label>

              <vue-slider
                style=""
                :id="'slider-input-' + metric.name"
                v-model="metric.value"
                direction="ltr"
                :tooltip="'active'"
                :min="metric.min"
                :marks="metric.marks"
                :max="metric.max"
                :data="metric.data || undefined"
                :interval="metric.interval"
                class="mb-2 vue-slider-secondary"
                @change="(value, index) => onChange(metric.name, value)"
                @drag-end="(index) => onDragEnd(metric.name)"
              />
            </b-col>
            <b-col>
              <label :for="'input-' + metric.name"> </label>
              <b-form-input
                :id="'input-' + metric.name"
                v-model="metric.value"
                placeholder=""
                @input="onChange"
                :readonly="metric.disabledInput"
              ></b-form-input>
            </b-col>
            <!--
            <b-col>
              <label :for="'checkbox-' + metric.name"> </label>
              <b-form-checkbox
              :id="'checkbox-' + metric.name"
      v-model="status"
      name="checkbox-1"
      value="accepted"
      unchecked-value="not_accepted"
    >
      
    </b-form-checkbox>  
            
            
            </b-col>
            -->
          </b-row>
        </b-col>
      </b-row>



      <b-row style="margin-top: 3rem; margin-bottom: 1rem;">
        <b-col>
          <h2>Dados Meteorológicos</h2>
        </b-col>
      </b-row>      

      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics.filter(metric => metric.category == 'clima')" :key="metric.name">
          <b-form-checkbox
            v-model="metric.show"
            :value="true"
            :unchecked-value="false"
            switch
          >
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
          xl="6"
          v-for="metric in metrics.filter(metric => metric.category == 'clima')"
          :key="metric.name"
          v-show="metric.show"
        >
          <b-row 
            style="
              margin: 1rem;
              padding: 1rem;
              border: 1px solid;
              border-color: silver;
            "
          >
            <b-col lg="9" xl="9">
              <label :for="'slider-input-' + metric.name">{{
                metric.name
              }}</label>

              <vue-slider
                style=""
                :id="'slider-input-' + metric.name"
                v-model="metric.value"
                direction="ltr"
                :tooltip="'active'"
                :min="metric.min"
                :marks="metric.marks"
                :max="metric.max"
                :data="metric.data || undefined"
                :interval="metric.interval"
                class="mb-2 vue-slider-secondary"
                @change="(value, index) => onChange(metric.name, value)"
                @drag-end="(index) => onDragEnd(metric.name)"
              />
            </b-col>
            <b-col>
              <label :for="'input-' + metric.name"> </label>
              <b-form-input
                :id="'input-' + metric.name"
                v-model="metric.value"
                placeholder=""
                @input="onChange"
                :readonly="metric.disabledInput"
              ></b-form-input>
            </b-col>
            <!--
            <b-col>
              <label :for="'checkbox-' + metric.name"> </label>
              <b-form-checkbox
              :id="'checkbox-' + metric.name"
      v-model="status"
      name="checkbox-1"
      value="accepted"
      unchecked-value="not_accepted"
    >
      
    </b-form-checkbox>  
            
            
            </b-col>
            -->
          </b-row>
        </b-col>
      </b-row>


    </b-card-body>

    <template #footer>
      <slot name="footer-ecualizador">
        <b-button size="sm" @click="simulate" variant="primary"
          >Simular</b-button
        >
      </slot>
    </template>
  </b-card>
</template>
  
  <script>
import
{
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BFormInput,
  BFormCheckbox
} from "bootstrap-vue";

const axios = require("axios");

import VueSlider from 'vue-slider-component'


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VueSlider,
    BFormInput,
    BFormCheckbox

  },

  directives: {
  },

  data: function ()
  {
    return {

      selected: [],
      options: this.metrics.map((metric) => { return { text: metric.name, value: metric } })

    };
  },
  created()
  {

  },

  mounted()
  {

  },

  methods: {

    simulate()
    {

      this.$emit('change')
    },



    searchChange(value)
    {
      this.$emit('search-change', value);
    },


    onDragEnd(metric)
    {


      console.log(metric + " drag_end")
    },

    onChange(metric, value)
    {

      /*
      if (this.timeout)
      {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() =>
      {
        this.$emit('change', metric, value);
      }, 3000); // delay
*/



    }


  },

  props: {
    metrics: {
      type: Array,
      required: true,
      default: () => []
    },

    cardTitle: {
      type: String,
      default: () => "Variables de Modelamiento"
    }
  },

};
  </script>
  
  <style lang="scss"></style>


<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>


  