<template>
  <section id="dashboard-prediccion">
    <b-row>
      <b-col>
        <stats :stats="stats"></stats>
      </b-col>
    </b-row>
    <b-row>
      <b-col>

        <b-card class="text-center">
          <b-avatar class="mb-1" variant="light-secondary" size="45" :badge="false" badge-variant="primary" badge-top
            badge-offset="-1.0em">
            <feather-icon size="21" icon="ActivityIcon" />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ quebra_probability }}
            </h2>
            <span>{{ $t('Probabilidad de Quebra') }}(%)</span>


          </div>
        </b-card>




      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-form-textarea id="textarea" placeholder="Enter something..." rows="10" max-rows="6" @change="debugChange"
            v-if="debug"></b-form-textarea>

          <ecualizador :metrics="metrics" @change="ecualizadorChange" @search-change="searchChange"></ecualizador>

          <template #overlay>
            <div class="text-center">
              <b-spinner label="Loading..." />
              <p id="cancel-label">{{ overlayMessage }}</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="grid-historico-runs" :debug="true" :configUrl="historicoInvocationsConfig"
          :dataUrl="historicoInvocationsData" @gridReady="onGridReady" @getRowsLoaded="onGetRowsLoaded"
          rowSelection="multiple" :rowClassRules="rowClassRules" :pinnedTopRowData="pinnedTopRowData"
          @selectionChanged="onSelectionChanged" @actionReceived="actionReceived">
          <template v-slot:actions>
            <div>
              <b-button-toolbar aria-label="Toolbar with button groups and input groups" justify>
                <b-button-group style="margin-bottom: 1rem">
                  <b-button size="sm" :disabled="disableCompareRuns" @click="compareRuns()" variant="primary">{{
                    $t('Comparar') }}</b-button>

                  <b-button size="sm" variant="secondary" :disabled="disableRellenar" @click="fillData()">{{
                    $t('Rellenar') }}</b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </template>
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
  
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import VueSlider from 'vue-slider-component'

import useApiServices from '@/services/useApiServices.js';

import HistoricoPlanificacionesActions from "./HistoricoPlanificacionesActions.vue";

import Ecualizador from "./Planificador/Ecualizador.vue";
import Stats from "./Planificador/Stats.vue";


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Ecualizador,
    Stats,

    BOverlay,
    BSpinner,



    historicoPlanificacionesActions: HistoricoPlanificacionesActions


  },

  methods: {

    debugChange(value)
    {

      console.log(value)

      let decoded = JSON.parse(value)


      for (let variable in decoded)
      {

        this.metrics.forEach(metric =>
        {

          if (metric.name == variable)
          {

            let metric_value_ini = metric.value
            let metric_value_fin = decoded[variable]

            let percent = (100 * (metric_value_fin - metric_value_ini) / (metric.max - metric.min)).toFixed(2)

            metric.value = metric_value_fin

            console.log({ variable, metric_value_ini, metric_value_fin, percent })


          }

        })


      }



    },


    roundValue(value, decimales = 2)
    {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },


    actionReceived(action_name, data, params)
    {
      console.log("actionReceived", { action_name, data, params })
    },

    searchChange(value)
    {
      console.log(value)

      this.metrics.forEach(metric =>
      {

        if (metric.name.match(new RegExp(value, 'i')))
        {
          metric.show = true
        } else
        {
          metric.show = false

        }



      })

    },

    ecualizadorChange()
    {


      let metrics_values = this.metrics.map(metric => { return { "name": metric.name, "value": metric.value } });

      let data = { service_name: this.service_name, metrics: metrics_values };

      this.overlayMessage = "Cargando predicción..."
      this.showOverlay = true

      console.log("ecualizadorChange", data)

      axios.post(useApiServices.modelsCreateInvocationUrl, data)
        .then((response) =>
        {

          console.log(response)

          this.quebra_probability = (response.data["PrediccionProbabilidadQuebra"] * 100).toFixed(2)


          this.stats.forEach(stat =>
          {


            if (stat.id in response.data)
            {
              let predictedValue = response.data[stat.id]

              stat.value = predictedValue
              stat.percentDiff = (predictedValue - stat.initialValue) / (stat.initialValue + 0.0000001)
              stat.ahorroDiarioVaporUSD = stat.percentDiff * stat.gastoDiarioVaporUSD
              stat.ahorroMensualVaporUSD = stat.percentDiff * stat.gastoMensualVaporUSD


            } else
            {
              stat.value = 0
              stat.percentDiff = 0
              stat.ahorroDiarioVaporUSD = 0
              stat.ahorroMensualVaporUSD = 0
            }

          })


          this.showOverlay = false
          this.$refs['grid-historico-runs'].refreshRows()

        })
        .catch((error) =>
        {
          this.showOverlay = false

        });





    },

    compareRuns()
    {

      this.$router.push({ name: 'compare-runs', query: { ids: this.selectedRows.map(run => run.id) } })
    },


    setEscenario(row)
    {
      let input_data_values = row.input_data
      let output_data_values = row.output_data

      this.metrics.forEach(metric =>
      {
        let metric_name = metric.name
        let metric_value = input_data_values[metric.name]

        if (metric_name == "Produto" && typeof metric_value == 'number')
        {
          let map_values = {
            0: "SAK16080",
            1: "SAK16085",
            2: "SAK16090"
          }
          metric_value = map_values[metric_value]
        }

        if (metric_name == "Status" && typeof metric_value == 'number')
        {
          let map_values = {
            0: "Normal",
            1: "Quebra",
          }
          metric_value = map_values[metric_value]
        }

        metric.value = metric_value

      })

      this.stats.forEach(stat =>
      {


        if (stat.id in output_data_values)
        {
          stat.initialValue = Math.round(output_data_values[stat.id] * 10000) / 10000
          stat.value = Math.round(output_data_values[stat.id] * 10000) / 10000
        } else
        {
          stat.initialValue = 0
          stat.value = 0
        }

      })


    },

    fillData()
    {

      this.setEscenario(this.selectedRows[0])

      this.gridApi.deselectAll()



    },

    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



    },

    onSelectionChanged(selectedRows)
    {

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      //gridColumnApi.autoSizeColumn('actions')

      /*
      this.loadVariables(() => {

        let model = gridApi.getModel()

        let firstRow = model.getRow(0)
        this.setEscenario(firstRow.data)


      })
*/



    },

    loadVariables()
    {
      this.overlayMessage = "Cargando variables..."
      this.showOverlay = true

      return useApiServices.modelsRead({ name: this.model_name })
        .then((response) =>
        {

          this.service_name = response.data.service_name

          let metrics = response.data.input_variables.map(variable =>
          {

            let min = Math.floor(variable.meta_data.minimo)
            let max = Math.ceil(variable.meta_data.maximo)

            let value = min + (max - min) / 2

            if (variable.meta_data.options)
            {
              return {
                value: null,
                name: variable.name,
                category: variable.meta_data.category,
                data: variable.meta_data.options,
                show: false,
                disabledInput: true

              }
            }
            else
            {
              return {
                name: variable.name,
                category: variable.meta_data.category,
                value: value,
                min: min,
                max: max,
                marks: [min, value, max],
                interval: 0.01,
                show: false,
                disabledInput: false

              }

            }



          });

          console.log(metrics)

          this.metrics = metrics

        })
        .catch((error) =>
        {

        });

    }


  },

  mounted()
  {



  },

  created()
  {

    this.loadVariables()
      .then(() =>
      {

        /*
        axios.post(useApiServices.modelsLastInvocationUrl)
          .then((response) =>
          {
            console.log("modelsLastInvocationUrl", response)
            this.setEscenario(response.data)

            this.pinnedTopRowData = [response.data]

            this.showOverlay = false

          });
*/
        axios.request({
          method: "get",
          url: useApiServices.optimizations.getLastEscenario,
          headers: {
            Accept: "application/json",
          },
        })
          .then((response) =>
          {

            let variables = response.data.meta_data

            console.log("getLastEscenario", variables)

            //Se cargan los inputs de las variables con los valores del ultimo escenario disponible en la planta

            this.metrics.forEach(metric =>
            {
              let metric_name = metric.name
              let metric_value = variables[metric.name]

              if (metric_name == "Produto" && typeof metric_value == 'number')
              {
                let map_values = {
                  0: "SAK16080",
                  1: "SAK16085",
                  2: "SAK16090"
                }
                metric_value = map_values[metric_value]
              }

              if (metric_name == "Status" && typeof metric_value == 'number')
              {
                let map_values = {
                  0: "Normal",
                  1: "Quebra",
                }
                metric_value = map_values[metric_value]
              }

              metric.value = metric_value

            })

            //Se crea una simulacion para cargar las estadisticas

            if (["SAK16080", "SAK16085", "SAK16090"].includes(variables['Produto']))
            {

              let metrics_values = this.metrics.map(metric => { return { "name": metric.name, "value": metric.value } });

              let data = { service_name: this.service_name, metrics: metrics_values };

              axios.post(useApiServices.modelsCreateInvocationUrl, data)
                .then((response) =>
                {
                  console.log("modelsCreateInvocationUrl", response)


                  this.quebra_probability = (response.data["PrediccionProbabilidadQuebra"] * 100).toFixed(2)


                  this.stats.forEach(stat =>
                  {


                    if (stat.id in response.data)
                    {
                      let predictedValue = response.data[stat.id]

                      stat.value = predictedValue
                      stat.initialValue = predictedValue
                      stat.percentDiff = (predictedValue - stat.initialValue) / (stat.initialValue + 0.0000001)
                      stat.ahorroDiarioVaporUSD = stat.percentDiff * stat.gastoDiarioVaporUSD
                      stat.ahorroMensualVaporUSD = stat.percentDiff * stat.gastoMensualVaporUSD


                    } else
                    {
                      stat.value = 0
                      stat.initialValue = 0
                      stat.percentDiff = 0
                      stat.ahorroDiarioVaporUSD = 0
                      stat.ahorroMensualVaporUSD = 0
                    }

                  })

                  this.showOverlay = false


                })





            }
            else
            {

              this.showOverlay = false

            }






          })




      })








  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      debug: debug,

      showOverlay: false,
      overlayMessage: "Cargando...",

      quebra_probability: 0,


      rowClassRules: {

        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }

          return params.data.default === 1;
        }

      },

      gridApi: null,

      itemsData: [],
      pinnedTopRowData: [],

      historicoInvocationsConfig: useApiServices.historicoInvocationsConfig,
      historicoInvocationsData: useApiServices.historicoInvocationsData,

      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],


      service_name: null,
      model_name: "Klabin_Vapor_CE",
      metrics: [],


      stats: [
        {
          id: 'CEdeVaporTotal',
          name: 'Vapor CE',
          icon: 'ActivityIcon',
          variant: 'light-secondary',
          initialValue: 0,
          percentDiff: 0,
          value: 0,
          unit: 't vapor/t papel',
          gastoDiarioVaporUSD: 5416,
          gastoMensualVaporUSD: 162484,

          ahorroDiarioVaporUSD: 0,
          ahorroMensualVaporUSD: 0,

        },
        {
          id: 'VaporBajaPresion',
          name: 'Vapor baja presión',
          icon: 'ActivityIcon',
          variant: 'light-secondary',
          initialValue: 0,
          percentDiff: 0,
          value: 0,
          unit: 't/h',
          gastoDiarioVaporUSD: 2865,
          gastoMensualVaporUSD: 85962,
          ahorroDiarioVaporUSD: 0,
          ahorroMensualVaporUSD: 0,
        },
        {
          id: 'VaporMediaPresion',
          name: 'Vapor media presión',
          icon: 'ActivityIcon',
          variant: 'light-secondary',
          initialValue: 0,
          percentDiff: 0,
          value: 0,
          unit: 't/h',
          gastoDiarioVaporUSD: 3123,
          gastoMensualVaporUSD: 93702,
          ahorroDiarioVaporUSD: 0,
          ahorroMensualVaporUSD: 0,

        },



      ]



    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>


<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
  